<template>
  <div class="my-2">
    <template v-if="selectedFlag">
      <div class="cursor-pointer">
        <div class="available-action flex px-4 py-2" @click="downloadShipments()">
          <feather-icon icon="DownloadIcon" svgClasses="w-4 h-4" class="self-center"/>
          <span class="text-sm ml-2">{{ $t('Download') }} {{ `(${selected.length})` }}</span>
        </div>
      </div>
    </template>
    <template v-else>
      <vx-tooltip position="bottom" color="warning" :text="`${$t('Please select the order you want to download!')}`">
        <div class="cursor-not-allowed">
          <div class="pointer-events-none unavailable-action flex px-4 py-2">
            <feather-icon icon="DownloadIcon" svgClasses="w-4 h-4" class="self-center"/>
            <span class="text-sm ml-2">{{ $t('Download') }}</span>
          </div>
        </div>
      </vx-tooltip>
    </template>
  </div>
</template>

<script>
export default {
  props: ['selected'],
  data () {
    return {
      headerTitle: ['Created', 'Payout Method', 'Merchant ID', 'Merchant Name', 'Statement Number', 'Creditor Name', 'Creditor Account Number', 'Creditor Bank', 'Payout'],
      headerVal: ['created', 'payout_method', 'merchant_id', 'merchant_name', 'number', 'creditor_name', 'creditor_number', 'creditor_bank', 'payout'],
      upfrontHeaderTitle: ['Payout Method', 'Merchant ID', 'Merchant Name', 'Pickup Date', 'Creditor Name', 'Creditor Account Number', 'Creditor Bank', 'Upfront Amount'],
      upfrontHeaderVal: ['payout_method', 'merchant_id', 'merchant_name', 'number', 'creditor_name', 'creditor_number', 'creditor_bank', 'payout_amount'],
      selectedFlag: false
    }
  },
  watch: {
    selected (val) {
      if (val.length !== 0) {
        this.selectedFlag = true
      } else {
        this.selectedFlag = false
      }
    }
  },
  methods: {
    downloadShipments () {
      let list = []
      import('@/vendor/Export2Excel').then(excel => {
        if (this.$route.name === 'finance-upfront') {
          list = this.selected
        } else {
          list = this.selected.filter(element => element.v2 && element.v2.payout > 0)
        }
        const data = this.formatJson(this.$route.name === 'finance-upfront' ? this.upfrontHeaderVal : this.headerVal, list)
        excel.export_json_to_excel({
          header: this.$route.name === 'finance-upfront' ? this.upfrontHeaderTitle : this.headerTitle,
          data,
          filename: this.$route.name === 'finance-upfront' ? 'Upfront' : 'Statements',
          autoWidth: true
        })
      })
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        switch (j) {
        case 'merchant_id':
          return v.merchant.id
        case 'created':
          return `${v.created.split('T')[0]  } - ${  v.created.split('T')[1].split('.')[0]}`
        case 'merchant_name':
          return v.merchant.name
        case 'payout_method':
          return v.payout_method ? v.payout_method.bank ? 'Bank' : v.payout_method.wallet ? 'Wallet' : '' : 'N/A'
        case 'creditor_number':
          return v.payout_method && v.payout_method.bank ? v.payout_method.bank.account_number : v.payout_method && v.payout_method.wallet ? v.payout_method.wallet.number : 'N/A'
        case 'creditor_name':
          return v.payout_method && v.payout_method.bank ? v.payout_method.bank.creditor_name : 'N/A'
        case 'creditor_bank':
          return v.payout_method && v.payout_method.bank ? v.payout_method.bank.name : 'N/A'
        case 'payout':
          return v.v2 ? v.v2.payout : v.net_cod_due
        }
        return v[j] ? v[j] : 'N/A'
      }))
    }
  }
}
</script>